import 'magnific-popup';

import nav from './modules/nav';
import home_carousel from './modules/home-carousel';
import home_featured from './modules/home-featured';
import releases_search from './modules/releases-search';
import single from './modules/single';
import scroll_to_top from './modules/scroll-top';

$( document ).ready( function ( $ ) {
	nav();
	home_carousel();
	home_featured();
	releases_search();
	single();
	scroll_to_top();
	
} );